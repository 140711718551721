import React, {useEffect} from 'react'
import WebContainer from '../components/webcontainer'
import SocialProof from '../components/socialproof'
import PrivacyFirst from '../components/privacyfirst'
import ForEnterprise from '../components/forenterprise'
import { Row, Col, Icon, Popover, Button, Carousel, Card } from 'antd'
import shareImage from '../images/sync_share_image.png'
import SEO from '../components/SEO'
import howItWorksImg1 from '../images/link-account.png'
import howItWorksImg2 from '../images/select-calendars.png'
import howItWorksImg3 from '../images/sync-calendars.png'

import { getAuthState } from '../utils/auth'
import WebsiteFooter from '../components/WebsiteFooter'
import WebsiteHeader from '../components/WebsiteHeader'
import CtaForm1 from '../components/ctaform1'
import Step3 from '../images/step_3.png'
import Step2 from '../images/step_2_sync.png'
import Step1 from '../images/step_1_sync.png'
import Sync from '../images/svg/sync-lite.svg'
import Highlight from '../components/Highlight'
import Shield from '../images/svg/bg-shield.svg'
import Brands from '../images/brands.png'

import { Link } from 'gatsby'
import { faDove } from '@fortawesome/free-solid-svg-icons'
const Index = ({ data }) => {


  const authState = getAuthState()
  useEffect(() => {
    if(window.location.host.includes("app")) {
      window.location.href = ('/dashboard'); // Redirect to the desired route
     }
}, []);
  const SupportedAccounts = (
    <div>
      <p>
        Microsoft 365 support requires a plan with Exchange Online.
        <br />
        <a href="https://help.calendarbridge.com/faq/how-many-sync-connections-do-i-need/">
          Learn more about supported account types.
        </a>
      </p>
    </div>
  )
  const SectionHero = ({}) => {
    return (
      <div
        className="flex flex-col lg:flex-row items-center justify-start h-full  w-full "
        style={{ maxWidth: 1200 }}
      >
        <div className="w-full lg:w-1/3 text-white  flex flex-col lg:order-1 order-2">
          <div className="flex font-lato text-white text-5xl lg:text-5xl space-x-1 lg:space-x-0 lg:flex-col  mb-4 justify-center lg:justify-start">
            <div className="flex flex-row">
              Protect Your <br /> Time & Privacy
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="text-white text-lg lg:w-90 text-center lg:text-left">
              CalendarBridge provides{' '}
              <strong>
                automatic, real-time Outlook & Google calendar syncing
              </strong>{' '}
              with advanced privacy controls. Updates to one calendar sync to
              the other(s) nearly instantly and people only see the details you
              want them to.
            </div>

            <div className="flex justify-center lg:justify-start lg:w-100">
              <Link
                className="lg:w-100 text-xl text-center rounded-md pl-4 pr-4 pt-2 pb-2 mt-6 mb-5 hover:bg-burnt bg-dark_burnt text-white lg:w-80"
                to="/signup?referral=sync"
              >
                Try CalendarBridge For Free
              </Link>
            </div>
            <div className="text-white font-roboto text-lg text-center lg:text-left ">
              Already a user?{' '}
              <Link className="text-brand_yellow underline" to="/dashboard">
                Access your dashboard
              </Link>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center lg:w-2/3 lg:order-2 order-1 lg:h-96">
          <div>
            <Sync></Sync>
          </div>
        </div>
      </div>
    )
  }

  const Explainer = ({}) => {
    return (
      <div className="text-lg flex pl-3 pr-3 lg:pr-0 lg:pl-0 pt-20 pb-20 flex-col bg-brand_blue items-center text-white justify-center self-center w-full text-center">
        <div className="font-lato text-3xl lg:text-5xl mb-8">
          Why CalendarBridge Sync?
        </div>
        <div className="grid lg:divide-x  grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 lg:space-y-0  mt-10 ">
          <div>
            <h2 className="lg:w-80 mb-8 font-bold font-lato text-center text-white text-2xl">
              Fast and easy setup
            </h2>
            <div className="lg:w-80 font-roboto text-justify text-md">
              Setup takes just 5 minutes, with nothing to install or download --
              keep using Outlook, Google Calendar, or whatever other calendar
              app you prefer. CalendarBridge works in the background so that you
              always see your accurate availability regardless of whether you
              check from your desktop, mobile device, or web browser.
            </div>
          </div>
          <div>
            <h2 className="lg:w-80 mb-8 font-bold font-lato text-center text-white text-2xl">
              Fine-grained controls
            </h2>
            <div className="lg:w-80 lg:pl-4 font-roboto  text-justify text-md">
              Choose to sync only "free/busy," full event details, or somewhere
              in between -- link your Google & Outlook calendars while ensuring
              no one sees details they shouldn't. You can also customize the
              color and subject of synced events to suit your needs -- merge
              availability across calendars while keeping them easy to read at a
              glance.
            </div>
          </div>
          <div>
            <h2 className="lg:w-80 mb-8 font-bold font-lato text-center text-white text-2xl">
              Private and secure{' '}
            </h2>
            <div className="lg:w-80 lg:pl-8 font-roboto text-justify text-md">
              CalendarBridge never sees or stores your Google or Microsoft login
              credentials; we never store or analyze any of the details of your
              calendar events; and we do not have access to your emails.
              Likewise, connecting your work and personal calendars through
              calendarbridge does not give your work any access to your personal
              calendar.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SimpleSetup = ({}) => {
    return (
      <>
        <div className="flex font-lato text-2xl lg:text-5xl text-brand_dark_blue w-full justify-center mb-12 mt-12 flex-row gap-3">
          <Highlight label="Simple" color="creme"></Highlight>
          <div>Setup</div>{' '}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5">
          <div></div>
          <div className="font-lato text-lg text-brand_dark_blue mb-12">
            <div className="pr-12 pl-6">
              Step 1 <br />
              <span className=" text-neutral_4 font-roboto">
                {' '}
                Link your Microsoft and Google accounts. We never see your
                credentials.
              </span>
            </div>
            <img src={Step1} />{' '}
          </div>
          <div className="font-lato text-lg text-brand_dark_blue mb-12">
            <div className="pr-12 pl-6">
              Step 2 <br />{' '}
              <span className=" text-neutral_4 font-roboto">
                Select calendars to sync and set your privacy controls so your
                peers never see sensitive calendar data.
              </span>
            </div>
            <img src={Step2} />
          </div>
          <div className="font-lato text-lg text-brand_dark_blue mb-12">
            <div className="pr-12 pl-6">
              Step 3 <br />{' '}
              <span className=" text-neutral_4 font-roboto">
                You’re done! CalendarBridge keeps your calendars in sync in real
                time so you never get double-booked again.
              </span>
            </div>
            <img src={Step3} />
          </div>
          <div></div>
        </div>
      </>
    )
  }

  const Video = ({ src }) => {
    return (
      <>
        <div className="container max-w-screen-md mx-auto pr-2 pl-2 mb-20">
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src={src}
              title="CalendarBridge Calendar Sync Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </>
    )
  }

  const CallToAction = () => {
    return (
      <div className="bg-creme grid grid-cols-1 justify-center pt-12 pb-20  self-center w-full">
        <div className="flex font-lato text-2xl lg:text-5xl text-brand_dark_blue w-full justify-center mb-10 flex-row gap-3">
          <div>Ready to</div> <Highlight label="Try It Free?" color="white" />
        </div>
        <div className="flex justify-center">
          <CtaForm1 referral="sync"></CtaForm1>
        </div>
      </div>
    )
  }

  const Section = ({ title, content }) => {
    return (
      <div
        className="flex flex-col lg:flex-row  justify-center w-full mb-0 lg:mb-8 "
        style={{ maxWidth: 1200, zIndex: 1 }}
      >
        <div className="flex justify-center lg:justify-start lg:w-1/3 w-full ">
          <div className="font-lato text-brand_dark_blue text-5xl flex flex-row  mt-4 lg:mb-0 mb-12">
            {title}
          </div>
        </div>
        <div className="flex lg:w-2/3 w-full justify-center  lg:justify-start">
          {content}
        </div>
      </div>
    )
  }
  return (
    <>
      <SEO
        title="Sync All Your Calendars | CalendarBridge"
        description="CalendarBridge digital calendar sync checks all your calendars so your availability is always up-to-date and easy to book with multiple parties."
        image={shareImage}
      />
      <div className="flex flex-col justify-center">
        <div className="bg-brand_dark_blue grid grid-cols-1">
          <WebsiteHeader theme="dark" authState={authState}></WebsiteHeader>
          <div className="flex justify-center mt-10 mb-10">
            <SectionHero></SectionHero>
          </div>
        </div>
        <div className="flex font-lato text-2xl lg:text-5xl text-brand_dark_blue w-full justify-center mb-12 mt-12 flex-row gap-3">
          <div>Tired of</div>{' '}
          <Highlight label="Juggling Calendars?" color="creme"></Highlight>
        </div>
        <div>
          <Video src="https://www.youtube-nocookie.com/embed/5hzb1hJ4E1o"></Video>
        </div>

        <Explainer></Explainer>
        <SimpleSetup></SimpleSetup>
        <div className="flex font-lato text-2xl lg:text-5xl text-brand_dark_blue w-full justify-center mb-20 mt-12 flex-row gap-3">
          See it in action
        </div>
        <Video src="https://www.youtube-nocookie.com/embed/MjSwH4m0NLE"></Video>
        <div className="flex bg-brand_dark_blue w-full items-center justify-center justify-items-center flex-col pt-8 pb-8">
          {/* <div className="flex flex-col justify-center justify-items-center self-center justify-self-center"> */}
          <Section
            title={
              <div className="font-lato text-white text-2xl lg:text-5xl flex flex-col justify-center lg:justify-left lg:text-left text-center">
                Busy people love{' '}
                <Highlight
                  label="CalendarBridge"
                  color="brand_blue"
                ></Highlight>
              </div>
            }
            content={
              <div className="lg:ml-2 grid grid-cols-1 pl-2 pr-2 lg:pl-0 lg:pr-0 font-bold lg:grid-cols-2 gap-2  lg:mt-8 items-center self-center place-self-center justify-center justify-items-center">
                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “Finally a sync software that is easy, inexpensive, and
                    works!”
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Justin S.</div>
                    <div className="text-xs">
                      <div>Owner</div>
                      <div>Financial Services, Self-employed</div>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “The absolute best multi-directional multi-calendar sync”
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Judah J.</div>
                    <div className="text-xs">
                      <div>Principal</div>
                      <div>IT and Services, 1-10 employees</div>
                    </div>
                  </div>
                </div>
                <div className="bg-white w-full rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “The best thing I've found in 2020”<p></p>
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">
                      Brendan S.
                    </div>
                    <div className="text-xs">
                      <div>Operations & Enablement</div>
                      <div>Telecommunications, 1001-5000 employees</div>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 mb-4 text-center font-bold">
                    “Saved me from calendar madness!”
                    <p></p>
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Lucas D.</div>
                    <div className="text-xs">
                      <div>Chief Operating Officer</div>
                      <div>E-Learning, 11-50 employees</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          ></Section>
        </div>
        <div className=" bg-white pt-24 flex w-full flex-col items-center justify-center justify-items-center">
          <Section
            title={
              <div className=" bg-white">
                <div className="font-lato text-brand_dark_blue text-2xl lg:text-5xl flex flex-row">
                  <div>
                    Trusted by{' '}
                    <Highlight label="global" color="creme" w={32}></Highlight>{' '}
                    organizations
                  </div>
                  {/* <div className="relative">
                <span className="absolute">new</span>
                <div className="mt-8 w-24 h-4 bg-creme"></div>
              </div> */}{' '}
                </div>
              </div>
            }
            content={
              <div className="pl-3 pr-2  lg:pr-0 mb-16 lg:mb-0">
                <img width="600" src={Brands}></img>
              </div>
            }
          ></Section>

          <Section
            title={
              <div className="bg-white">
                <div className="font-lato text-brand_dark_blue text-2xl lg:text-5xl flex flex-col">
                  <div>
                    Made{' '}
                    <Highlight label="for you" color="creme" w={20}></Highlight>{' '}
                  </div>
                  {/* <div className="relative">
                <span className="absolute">new</span>
                <div className="mt-8 w-24 h-4 bg-creme"></div>
              </div> */}{' '}
                </div>
              </div>
            }
            content={
              <div className="lg:ml-1 grid lg:grid-cols-3 grid-cols-1 lg:space-x-8 text-center lg:text-left justify-center items-center">
                <div className="flex flex-col justify-between h-full">
                  <div className="font-lato text-lg text-brand_dark_blue mb-2">
                    There’s nothing to install.
                  </div>
                  <div
                    className="font-roboto text-brand_dark_blue text-center lg:text-left text-lg p-2 lg:p-0"
                    style={{ height: 168 }}
                  >
                    Simply customize your settings and our cloud-based
                    technology will take care of the rest.{' '}
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                  <div className="font-lato text-lg text-brand_dark_blue mb-2">
                    We provide expert security.
                  </div>
                  <div
                    className="font-roboto text-brand_dark_blue p-2 lg:p-0 text-lg"
                    style={{ height: 168 }}
                  >
                    Our background in cybersecurity means we understand
                    enterprise-grade protection and implement it every day.
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                  <div className=" font-lato text-lg text-brand_dark_blue mb-2 ">
                    Real-time availability makes scheduling easy.{' '}
                  </div>
                  <div className="font-roboto text-brand_dark_blue text-lg p-2 lg:p-0">
                    Because CalendarBridge syncs all of your calendars across
                    all domains and platforms, your availability is always
                    accurate and up-to-date.
                  </div>
                </div>
              </div>
            }
          ></Section>
        </div>

        <div className="flex flex-col bg-brand_blue w-full items-center justify-center justify-items-center pt-8 pl-2 lg:pl-8">
          <Section
            title={
              <div className="mt-12 text-white font-lato  text-3xl lg:text-5xl flex lg:flex-col flex-row">
                <div className=" flex flex-row">
                  <div>Your </div>
                  <div className="w-2"></div>
                  <Highlight
                    label="privacy"
                    color="brand_dark_blue"
                    w={32}
                  ></Highlight>{' '}
                </div>
                <div className="ml-2 lg:ml-0">comes first</div>
              </div>
            }
            content={
              <div className="flex flex-col items-center p-2 lg:p-0 lg:mt-20 lg:w-96 font-roboto">
                <div>
                  <div>
                    <div className="text-white text-lg mb-4">
                      Our goal is simplicity in calendaring – not hoarding your
                      personal information. That’s why we never analyze or store
                      any of your calendar data.{' '}
                    </div>

                    <div className="text-white text-lg mb-2">
                      CalendarBridge never views or stores your Google or
                      Microsoft login credentials, does not stores details of
                      your calendar events, and has no access to your emails.
                    </div>
                    <Link
                      to="https://help.calendarbridge.com/calendarbridge-security/"
                      className="text-white text-lg underline bold hover:text-brand_dark_blue hover:underline"
                    >
                      More about our privacy and security
                    </Link>
                  </div>
                </div>
              </div>
            }
          ></Section>

          <Section
            title={
              <div className="text-3xl items-center text-center lg:text-left lg:text-5xl font-lato mt-12 text-white ">
                <Highlight
                  label="Enterprise ready"
                  color="brand_dark_blue"
                  w={48}
                ></Highlight>{' '}
                calendar solution
              </div>
            }
            content={
              <div className="flex flex-col lg:mt-20 font-lato">
                <div className="lg:w-96 p-2 lg:p-0">
                  <div>
                    <div className="text-white text-lg mb-4 font-roboto">
                      In addition to our best-in-class security and privacy
                      practices, we offer IT adminstrators all the tools they
                      need to manage CalendarBridge for teams of all sizes.
                      Manage billing, licenses, and custom privacy controls all
                      from our intuitive admin portal.
                    </div>
                    <Link
                      to="https://help.calendarbridge.com/doc/group-account-management/managed-syncs-vs-managed-users/"
                      className="text-white text-lg underline bold hover:text-brand_dark_blue hover:underline"
                    >
                      Learn more about our admin tools
                    </Link>
                  </div>
                </div>
              </div>
            }
          ></Section>

          <div style={{ position: 'absolute', right: 0, fillOpacity: 0.3 }}>
            <div className="flex-grow"></div>
            <Shield></Shield>
          </div>
        </div>
        <CallToAction></CallToAction>
      </div>
      <WebsiteFooter></WebsiteFooter>
    </>
  )
}

export default Index
